import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/sezer/Documents/Projects/talepnet-landing/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Hint } from '@nejcm/docz-theme-extended';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`const intl = useIntl()`}</p>
    <h2 {...{
      "id": "settings"
    }}>{`Settings`}</h2>
    <p>{`Ayarlar menüsü ve altındaki tüm diğer menüler sadece firmanın hesap sahibi tarafından görülebilir. Firma hesabınızda yer alan diğer kullanıcıların ayarlar menüsünde görüntüleme dahil hiçbir yetkisi yoktur.`}</p>
    <h3 {...{
      "id": "hesap-ayarları"
    }}>{`Hesap Ayarları`}</h3>
    <p>{`TalepNET mevcut hesap bilgilerini görüntülersiniz. Kullanıcı sayısını arttırabilir veya azaltabilir, ödeme yönteminizi değiştirebilirsiniz. Ücretli bir plandaysanız yenileme tarihini görebilirsiniz.`}</p>
    <ul>
      <li parentName="ul">{`Kullanıcı Lisansı: Mevcut kullanıcı lisans sayınızdır.`}</li>
      <li parentName="ul">{`Aktif Kullanıcılar: Sahip olduğunuz kullanıcı lisanslarınızdan kaç tanesini kullandığınızı gösterir.`}</li>
    </ul>
    <p>{`Kullanıcı sayısını değiştirmek için "Planı Değiştir" tıklayın. Açılacak sayfada kullanıcı sayısına göre fiyatlarımızı inceleyin ve istediğiniz kullanıcı sayısını belirleyin. Eğer ödeme yönteminiz henüz kayıtlı değilse ödeme sayfasından işlemlerinize devam ederek sonuçlandırın.`}</p>
    <p>{`Ödeme yöntemi eklemek veya değiştirmek için "Ödeme Yöntemi Değiştir" tıklayın. Açılacak sayfada yeni ödeme yönteminizi belirleyin.`}</p>
    <h3 {...{
      "id": "firma"
    }}>{`Firma`}</h3>
    <p>{`Firmanıza ait genel bilgileri girin.`}</p>
    <h3 {...{
      "id": "organizasyon-şeması"
    }}>{`Organizasyon Şeması`}</h3>
    <p>{`TalepNET kullanıcılarını oluştururken her kullanıcı için bir pozisyon seçimi yapılır. İş akışları ve yetkilerin oluşturulmasında kritik olan rol bilgileri pozisyon içinde belirlenmektedir. Bu nedenle kullanıcı eklemeden önce firmanıza ait pozisyon tanımlarını hiyerarşik düzende oluşturmanız önemlidir.`}</p>
    <p>{`Hesabınızı ilk defa oluşturduğunuzda kendi kullanıcınız varsayılan olarak otomatik oluşturulan Sistem Yöneticisi pozisyonuna atanır. Yine varsayılan olarak kendi pozisyonunuzda tüm roller işaretli olacaktır. İsterseniz kendi kullanıcınızı daha sonra eklediğiniz başka bir pozisyona atayabilir veya rollerinizi değiştirebilirsiniz.`}</p>
    <p>{`Organizasyon şemasındaki pozisyonlara ekle ile yeni pozisyon ekleyebilir ve düzenle ile değişiklik yapabilirsiniz.`}</p>
    <ul>
      <li parentName="ul">{`Adı: Pozisyon adını yazın. (Örn: Satın Alma Elemanı)`}</li>
      <li parentName="ul">{`Bağlı pozisyon: Hangi pozisyona bağlı olduğunu seçin. (Örn: Satın Alma Müdürü)`}</li>
      <li parentName="ul">{`Roller: Pozisyonda olmasını istediğiniz yetki rollerini seçin. (Depo, Satın Alma, Onaylar, Muhasebe)`}
        <ul parentName="li">
          <li parentName="ul">{`Depo: "Modüller/Envanter Yönetimi" altında "Birim Kodları", "Ürün Grupları" ve "Ürünler" tanımlarında ekleme/düzenleme/silme yetkilerine sahiptir. Konsolda "Depo" widget'ı görünür hale gelir. Aynı zamanda Proses oluştururken eğer "Depo" süreci eklerseniz sadece "Depo" rolüne sahip pozisyonlardan seçim yapabilirsiniz.`}</li>
          <li parentName="ul">{`Satın Alma: "Modüller/Satın Alma Yönetimi" altında "Tedarikçiler" ve "Satın Alma Anlaşmaları" tanımları görünür hale gelir ve ekleme/düzenleme/silme yetkilerine sahiptir. Konsolda "Satın Alma" widget'ı görünür hale gelir. Proses oluştururken "Satın Alma" süreci eklediğinizde sadece "Satın Alma" rolüne sahip pozisyonlardan seçim yapabilirsiniz.`}</li>
          <li parentName="ul">{`Onaylar: Konsolda "Talep Onayları" ve "Satın Alma Onayları" widget'ları görünür hale gelir. Proses oluştururken "Onay" süreci eklediğinizde sadece "Onaylar" rolüne sahip pozisyonlardan seçim yapabilirsiniz.`}</li>
          <li parentName="ul">{`Muhasebe: Konsolda "Muhasebe" widget'ı görünür hale gelir. Satın alma faturaları için yetkilidir.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Aktif: Kullanıcıya pozisyon atarken ve proses oluşturken ilgili pozisyonları seçerken yalnız aktif olan pozisyonlardan seçim yapılabilir.`}</li>
    </ul>
    <h3 {...{
      "id": "şubeler"
    }}>{`Şubeler`}</h3>
    <p>{`Şube/Lokasyon tanımlarını yapabilirsiniz. İlk hesap yaratıldığında varsayılan olarak "Merkez Ofis" şubesi de oluşur.`}</p>
    <p>{`TalepNET kullanıcılarını oluştururken her kullanıcı için bir "Şube" seçimi yapılır. Hesabınızı ilk defa oluşturduğunuzda kendi kullanıcınız varsayılan olarak "Merkez Ofis" şubesine atanır. İsterseniz kendi kullanıcınızı daha sonra eklediğiniz başka bir şubeye atayabilirsiniz.`}</p>
    <p>{`Talep ve satın alma listelerinde kullanıcı adı ve pozisyonuyla beraber şube/lokasyon'u da görünür. Eğer firmanızın süreçlerinde lokasyonun belirleyici bir rolü varsa şube tanımlarını düzenleyebilirsiniz.`}</p>
    <ul>
      <li parentName="ul">{`Kodu: Şube kodunu yazın.`}</li>
      <li parentName="ul">{`Adı: Şube adını yazın.`}</li>
    </ul>
    <h3 {...{
      "id": "kullanıcılar"
    }}>{`Kullanıcılar`}</h3>
    <p>{`Aktif veya pasif tüm kullanıcılarınızı listeleyerek değişiklik yapabilirsiniz. TalepNET hesabınızı oluşturduğunuz kendi kullanıcınız sistemin ana kullanıcısıdır ve bu kullanıcıyı silemezsiniz veya başka bir kullanıcıyı hesap sahibi olarak atayamazsınız. "Admin" yetkisindeki kullanıcı olarak sadece siz sistem ayarlarında değişiklik yapabilirsiniz.`}</p>
    <p>{`Kullanıcılar sayfasında varsayılan olarak "Aktif Kullanıcılar" listelenir. İsterseniz seçim yaparak "Pasif Kullanıcılar"ı listelersiniz.`}</p>
    <p>{`"Aktif Kullanıcılar" mevcutta aktif durumda olan kullanıcılarınızdır. Kullanıcının aktif olması demek onun şu anda sistemde olduğu veya işlem yaptığı anlamında değil, sistemi kullanmak için lisansının aktif olduğu anlamındadır. TalepNET ile sahip olduğunuz kullanıcı lisans sayınız kaç adet ise en fazla o kadar sayıda aktif kullanıcıya sahip olabilirsiniz. Ama isterseniz bazı kullanıcılarınızı pasif hale getirerek onların yerine başka bir kullanıcıyı aktif yapabilirsiniz. TalepNET size bu konuda bir limit getirmez. Elbette ek kullanıcı lisansı alarak firma kullanıcı sayınızı arttırmayı da tercih edebilirsiniz.`}</p>
    <ul>
      <li parentName="ul">{`Aktif Kullanıcılar: Aktif kullanıcılarınız listelenir. En fazla toplam kullanıcı lisans sayınız kadar aktif kullanıcıya sahip olabilirsiniz.`}</li>
      <li parentName="ul">{`Pasif Kullanıcılar: Pasif kullanıcılarınız listelenir. Lisans durumu pasif olan bir kullanıcı TalepNET'e giriş veya işlem yapamaz.`}
        <ul parentName="li">
          <li parentName="ul">{`Yeni Kullanıcı Ekleme: Yeni kullanıcı eklemek için (+) tıklayın ve açılan sayfa üzerinde kullanıcı bilgilerini girin. Eğer mevcut planınızdaki kullanıcı lisans sayınız yeterli değilse "Lisans Uyarısı" alırsınız. Bu durumda "Hesap Ayarları" sayfasında plan değişikliği yaparak kullanıcı sayınızı arttırabilirsiniz. Kullanıcı bilgilerini girdikten sonra "Ekle" ile kullanıcıyı ekleyin. Kullanıcının eposta adresine bir davet mesajı gönderilecektir. Kullanıcı daveti onayladıktan sonra TalepNET'i kullanabilir. Kullanıcının daveti kabul edip etmediğini liste üzerinde "Durum" bilgisinden görebilirsiniz.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Eposta: Eposta adresini yazın. Eposta adresi daha sonra değiştirilemez.`}</li>
      <li parentName="ul">{`Kullanıcı Aktif: Kullanıcının sistemi kullanma lisansının olup olmadığını belirler. Yeni bir kullanıcı eklerken zorunlu olarak işaretli gelir. Daha sonra istenirse pasif hale getirilebilir.`}</li>
      <li parentName="ul">{`Şube: Kullanıcının şubesini/lokasyonunu seçin.`}</li>
      <li parentName="ul">{`Adı: Kullanıcının adını yazın.`}</li>
      <li parentName="ul">{`Soyadı: Kullanıcının soyadını yazın.`}</li>
      <li parentName="ul">{`Pozisyon(lar) Kullanıcının firmadaki pozisyonunu seçin. Her kullanıcı için tek bir pozisyon seçilebilir. (İlerleyen dönemde çoklu hale getirilmesi planlanıyor.)`}</li>
    </ul>
    <h3 {...{
      "id": "proses"
    }}>{`Proses`}</h3>
    <p>{`Satın alma yönetimi, satın alma süreçleri, talep, depo ve satın alma yönetici onayları farklı sektörlerde veya aynı sektörde yer alsa dahi farklı firmalarda
değişkenlik gösterebilir. TalepNET size kurumsal satın alma yönetiminizi oluşturmanız ve firmanızın satın alma prosedürlerini uygulamanız konusunda destek sağlar.`}</p>
    <p>{`"Proses" tasarımı, TalepNET Satın Alma Süreç Yönetimi içinde beyin görevindedir. İlk adımdan son adıma kadar firmanızın kendi iş akışlarını oluşturabilirsiniz.
Sade ve kullanışlı olan proses tasarım ekranı, her adımda sizi yönlendirerek sürecinizi düzgün ve kolay bir şekilde oluşturabilmeniz için tasarlandı.
Herhangi bir yazılım veya teknik bilginiz olmadan kolayca satın alma iç sürecinizi oluşturabilirsiniz.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
Yönetici onaylarını belirlerken tekrar düşünün
Satın alma sürecini yeni oluşturan firmalar proseslerini oluştururken genelde çok sayıda yöneticisini onay sürecine dahil etme eğiliminde olurlar. 
Buna karşın ilerleyen dönemlerde firmaların çoğunun onay alınması gereken yönetici sayısını azaltmaya karar verdikleri gözlemlenmiştir.
    </Hint>
    <p>{`TalepNET "Proses" tasarımı prensip olarak olabildiğince sade bir anlayışla oluşturuldu. Firmanızın sürecini oluştururken her adımda yapabilecekleriniz size seçenek
olarak gösterilir ve sizin seçim yapmanız beklenir. Seçiminize göre uygun olan yeni proses adımları size sunularak en son adıma kadar devam eder. Proses tasarımında
sadelik ve kolaylık önceliğimiz olsa da gelişen ihtiyaçlara çözüm sağlamak için zaman zaman yeni fonksiyonlar eklemekteyiz. Süreç tasarlarken firmanız adına farklı
fonksiyonlara ihtiyaç duyarsanız bize iletin.`}</p>
    <ul>
      <li parentName="ul">{`Proses online/offline: Prosesiniz hazır olduğunda "online" yapın. Yeni talep, depo, satın alma, onaylar, kısacası tüm işlemler prosesiniz online ise gerçekleştirilebilir.`}</li>
      <li parentName="ul">{`Revizyon: Prosesin geçerli revizyon numarasıdır. Hesabınızı ilk oluşturduğunuzda "revizyon" varsayılan olarak 0 olur. Proseslerinizi daha sonra gelişen ihtiyaçlarınıza göre revize edebilirsiniz. Her seferinde revizyon numarası bir artar. Proses revizyonu otomatik olarak sağlanır. Proses üzerinde gerekli değişiklikleri yaptıktan sonra "Kaydet" tıkladığınızda iki ihtimal vardır. Proses aynı revizyon içinde doğrudan kayıt edilir veya yeni bir revizyon olarak kaydedilir. Burada seçim size bırakılmadan otomatik olarak sağlanır.`}
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Doğrudan güncelleme: Eğer yaptığınız proses değişiklikleri eski prosesi ayrıca saklamayı gerektirmiyorsa sistem tarafından doğrudan güncelleme yapılır. Örnek olarak; mevcut prosesinizde satın almaya kadar tasarlayıp o halde kaydettiğinizi düşünelim. Daha sonra proseste kaldığınız yerden eklemeler yaparak (yönetici onayları gibi) kaydederseniz revizyona gerek olmadan doğrudan güncelleme sağlanacaktır.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Revizyon ile güncelleme: Mevcut prosesinizde bazı adımları silerseniz ve/veya araya farklı adımlar ekleyerek kaydederseniz yeni bir revizyon ile kayıt işleminin yapılacağı konusunda size bir bilgilendirme mesajı gelecektir.`}</p>
            <Hint type="info" variant="outer" mdxType="Hint">
Prosesi revize ettiğinizde daha önce oluşturulan talepler bu durumdan etkilenmez.
Yeni oluşturulan talepleriniz ise o anda geçerli olan prosese göre işletilir. Her talep, oluşturulduğu zamanda geçerli olan prosese göre en son adımına kadar devam eder.
            </Hint>
          </li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "satın-alma-yönetimi-proses-adımları"
    }}>{`Satın Alma Yönetimi Proses Adımları`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Yeni Form: Standart olarak prosesin en başında yer alır ve silinemez. Proses "Yeni Form" ile başlar. Yalnız "Gönderim" aksiyonu eklenebilir. Proseste talep formu gönderildikten sonraki adım "Talep Onayı", "Depo" veya "Satın Alma" olabilir.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Gönderim`}
            <ul parentName="li">
              <li parentName="ul">{`Talep Onayı: Yeni talep yönetici onayına gönderilmek istenirse seçilir. Hangi yöneticinin onayı isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Onaylar" rolüne sahip olan pozisyonlardır.`}</li>
              <li parentName="ul">{`Depo: Yeni talep depoya gönderilmek istenirse seçilir. Hangi depo yetkili pozisyonlara gönderilmek isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Depo" rolüne sahip pozisyonlardır.`}</li>
              <li parentName="ul">{`Satın Alma: Yeni talep satın almaya gönderilmek istenirse seçilir. Hangi satın alma yetkili pozisyonlara gönderilmek isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Satın Alma" rolüne sahip pozisyonlardır.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Talep Onayı: Talep Onayına "Onayla" ve "Reddet" olmak üzere iki aksiyon eklenebilir. Eğer "Onayla" aksiyonu eklenirse sonraki adım "Talep Onayı", "Depo" veya "Satın Alma" olabilir. Eğer "Reddet" aksiyonu seçilirse sonraki adım sadece "Sonlandır" olabilir.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Onayla`}
            <ul parentName="li">
              <li parentName="ul">{`Talep Onayı: Onaylanan bir talep başka bir yönetici onayına daha gönderilmek istenirse seçilir. Hangi yöneticinin onayı isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Onaylar" rolüne sahip olan pozisyonlardır.`}</li>
              <li parentName="ul">{`Depo: Onaylanan bir talep depoya gönderilmek istenirse seçilir. Hangi depo yetkili pozisyonlara gönderilmek isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Depo" rolüne sahip pozisyonlardır.`}</li>
              <li parentName="ul">{`Satın Alma: Onaylanan bir talep satın almaya gönderilmek istenirse seçilir. Hangi satın alma yetkili pozisyonlara gönderilmek isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Satın Alma" rolüne sahip pozisyonlardır.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Reddet`}
            <ul parentName="li">
              <li parentName="ul">{`Sonlandır: Reddedilen bir talep sonlandırılır.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Depo: Depoya "Teslim Et", "Reddet" ve "Satın Alma Gerekli" olmak üzere üç farklı aksiyon eklenebilir. Eğer "Teslim Et" aksiyonu veya "Reddet" aksiyonu eklenirse sonraki adım sadece "Sonlandır" olabilir. Eğer "Satın Alma Gerekli" aksiyonu eklenirse sonraki adım "Talep Onayı", "Depo" veya "Satın Alma" olabilir.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Teslim Et`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Sonlandır: Teslim edilen talep sonlandırılır. Stok takibi yapılan ürünler için ana depoda stok azalır.`}</p>
                <Hint type="info" variant="outer" mdxType="Hint">
Talebin depodan fiziksel olarak teslim edilmediği (örnek: hizmet ürünleri gibi) talepler için de "Teslim Et" işlemi yapılır. Bu tip ürünlerde yapılan "Teslim Etme" işlemi 
hizmetin gerçekleştirildiğinin onayı anlamındadır. Stok takibi yapılmayan ürün olacağı için ana depoda stok değişimi yaratmayacaktır.
                </Hint>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Reddet`}</p>
            <ul parentName="li">
              <li parentName="ul">{`Sonlandır: Reddedilen talep sonlandırılır.`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Satın Alma Gerekli`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Talep Onayı: Depo tarafından satın alma yapılması gerektiği belirtilen bir talep eğer daha öncesinde bir yönetici onayına gönderilmek istenirse seçilir. Hangi yöneticinin onayı isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Onaylar" rolüne sahip olan pozisyonlardır.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Satın Alma: Depo tarafından satın alma yapılması gerektiği belirtilen talep satın almaya gönderilmek istenirse seçilir. Hangi satın alma yetkili pozisyonlara gönderilmek isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Satın Alma" rolüne sahip pozisyonlardır.`}</p>
                <Hint type="info" variant="outer" mdxType="Hint">
Talebi Bölebilir: Eğer işaretlenirse, depoya gelen talep içinden belirlenen miktarda ürünü kısmen ayırarak farklı bir talepe dönüştürme yetkisi verilir. TalepNET'te depo 
teslim işleminde teslim edilen miktar için ayrı bir alan yoktur. Bu işlem için önce talep bölme yapılır. Örneğin ürün deponun elinde istenilen miktarda yoksa, elde olan 
kadar kısmı orijinal talepten ayrılarak ayrı bir talep haline getirilir. Ortaya çıkan aynı ürüne ait iki farklı miktardaki talepten elde olan teslim edilirken diğeri 
"Satın Alma Gerekli" seçilir veya şartlar gerektiriyorsa reddedilir.
                </Hint>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satın Alma: Satın Almaya "Reddet" ve "Satın Alma Başlat" olmak üzere iki farklı aksiyon eklenebilir. Eğer "Reddet" aksiyonu eklenirse sonraki adım sadece "Sonlandır" olabilir. Eğer "Satın Alma Başlat" aksiyonu eklenirse sonraki adım "Satın Alma Onayı" veya "Satın Alma Siparişi" olabilir.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Reddet`}
            <ul parentName="li">
              <li parentName="ul">{`Sonlandır: Reddedilen talep sonlandırılır.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Satın Alma Başlat`}
            <ul parentName="li">
              <li parentName="ul">{`Satın Alma Onayı: Satın alma formunda teklifler girilip ürünlere göre teklif/firma seçimi yapıldıktan sonra başlatılan satın alma süreci yönetici onayına gönderilmek istenirse seçilir. Hangi yöneticinin onayı isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Onaylar" rolüne sahip olan pozisyonlardır.`}</li>
              <li parentName="ul">{`Satın Alma Siparişi: Satın alma formunda teklifler girilip ürünlere göre teklif/firma seçimi yapıldıktan sonra başlatılan satın alma süreci eğer herhangi bir onay gerekmeden doğrudan sipariş adımına geçmek istenirse seçilir. Burada pozisyon seçimi yapılmaz. "Satın Alma Siparişi" adımında yetkili olacak pozisyonlar prosesin "Satın Alma" adımındaki pozisyonların aynısıdır.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satın Alma Onayı: Satın Alma Onayına "Onayla" ve "Reddet" olmak üzere iki aksiyon eklenebilir. Eğer "Onayla" aksiyonu eklenirse sonraki adım "Satın Alma Onayı" veya "Satın Alma Siparişi" olabilir. Eğer "Reddet" aksiyonu seçilirse sonraki adım sadece "Sonlandır" olabilir.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Onayla`}
            <ul parentName="li">
              <li parentName="ul">{`Satın Alma Onayı: Onaylanan bir satın alma başka bir yönetici onayına daha gönderilmek istenirse seçilir. Hangi yöneticinin onayı isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Onaylar" rolüne sahip olan pozisyonlardır.`}</li>
              <li parentName="ul">{`Satın Alma Siparişi: Onaylanan satın almanın sipariş adımına geçmesi istenirse seçilir. Burada pozisyon seçimi yapılmaz. "Satın Alma Siparişi" adımında yetkili olacak pozisyonlar prosesin "Satın Alma" adımındaki pozisyonların aynısıdır.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Reddet`}
            <ul parentName="li">
              <li parentName="ul">{`Sonlandır: Reddedilen satın alma sonlandırılır.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satın Alma Siparişi: Satın Alma Siparişine "Sipariş Ver" ve "İptal Et" olmak üzere iki aksiyon eklenebilir. Eğer "Sipariş Ver" aksiyonu eklenirse sonraki adım sadece "Mal Kabul" olabilir. Eğer "İptal Et" aksiyonu seçilirse sonraki adım sadece "Sonlandır" olabilir.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Sipariş Ver`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Mal Kabul: Satın alma ilgili tedarikçiye sipariş verildikten sonra teslim alma işleminden sorumlu olacak pozisyon belirlenir. Hangi depo yetkili pozisyonların tesellüm yapması isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Depo" rolüne sahip pozisyonlardır. Stok takibi yapılan ürünler için mal kabul işlemi sonunda ana depoda stok artar.`}</p>
                <Hint type="info" variant="outer" mdxType="Hint">
Siparişe konu olan ürünün fiziksel olmadığı (örnek: hizmet ürünleri gibi) süreçler için de "Mal Kabul" işlemi yapılır. Bu tip ürünlerde yapılan "Mal Kabul" işlemi 
hizmetin gerçekleştirildiğinin onayı anlamındadır. Stok takibi yapılmayan ürün olacağı için ana depoda stok değişimi yaratmayacaktır.
                </Hint>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`İptal Et`}</p>
            <ul parentName="li">
              <li parentName="ul">{`Sonlandır: İptal edilen satın alma sonlandırılır.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Mal Kabul: Mal Kabul adımına "Teslim Al" ve "İptal Et" olmak üzere iki aksiyon eklenebilir. Eğer "Teslim Al" aksiyonu eklenirse sonraki adım sadece "Depo Teslimi" olabilir. Eğer "İptal Et" aksiyonu seçilirse sonraki adım sadece "Sonlandır" olabilir.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Teslim Al`}
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Depo Teslimi: Tedarikçiden teslim alınan ürünü talep sahibine teslim etmesi gereken pozisyon belirlenir. Hangi depo yetkili pozisyonların teslim işlemini yapması isteniyorsa pozisyon listesinden istenilen seçim yapılır. Burada listelenen pozisyonlar yalnız "Depo" rolüne sahip pozisyonlardır. Stok takibi yapılan ürünler için teslim etme işlemi sonunda ana depoda stok azalır.`}</p>
                <Hint type="info" variant="outer" mdxType="Hint">
Siparişin depodan fiziksel olarak teslim edilmediği (örnek: hizmet ürünleri gibi) süreçler için de "Depo Teslimi" işlemi yapılır. Bu tip ürünlerde yapılan 
"Teslim Etme" işlemi hizmetin gerçekleştirildiğinin onayı anlamındadır. Stok takibi yapılmayan ürün olacağı için ana depoda stok değişimi yaratmayacaktır.
                </Hint>
              </li>
            </ul>
          </li>
          <li parentName="ul">{`İptal Et`}
            <ul parentName="li">
              <li parentName="ul">{`Sonlandır: İptal edilen satın alma sonlandırılır.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Depo (Mal kabul sonrası): Mal kabul sonrası "Depo" adımına "Teslim Et" ve "Reddet" olmak üzere iki farklı aksiyon eklenebilir. "Teslim Et" aksiyonu veya "Reddet" aksiyonu eklendiğinde sonraki adım sadece "Sonlandır" olur. Bu bölümde ilk "Depo" adımında olan tüm aksiyon ve özellikler yer almaz.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Teslim Et`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Sonlandır: Teslim edilen talep sonlandırılır. Stok takibi yapılan ürünler için ana depoda stok azalır.`}</p>
                <Hint type="info" variant="outer" mdxType="Hint">
Talebin depodan fiziksel olarak teslim edilmediği (örnek: hizmet ürünleri gibi) talepler için de "Teslim Et" işlemi yapılır. Bu tip ürünlerde yapılan "Teslim Etme" 
işlemi hizmetin gerçekleştirildiğinin onayı anlamındadır. 
Stok takibi yapılmayan ürün olacağı için ana depoda stok değişimi yaratmayacaktır.
                </Hint>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Reddet`}</p>
            <ul parentName="li">
              <li parentName="ul">{`Sonlandır: Reddedilen talep sonlandırılır.`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "parametreler"
    }}>{`Parametreler`}</h3>
    <p>{`İlgili sistem parametrelerini görüntüler ve değiştirebilirsiniz.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      